import {
  useAuthenticationClaims,
  forgetAuthenticationToken,
} from 'whodis-react';
import { storage } from 'whodis-react-storage-browser';

import { useQueryGetAuthRoleDesignations } from '../data/clients/svcGateway/queries/useQueryGetAuthRoleDesignations';
import { useAuthedBackupLoginGroup } from './useAuthedBackupLoginGroup';

/**
 * exposes auth from both login methods (whodis || password backup)
 */
export const useAuth = () => {
  const { logOutFromBackupLoginGroup } = useAuthedBackupLoginGroup();

  // grab data from whodis react hook
  const claims = useAuthenticationClaims();
  const whodisUserUuid = claims?.sub;

  // grab data from backup login hook
  const { authedBackupLoginGroup } = useAuthedBackupLoginGroup();

  const isLoggedInValue = whodisUserUuid || authedBackupLoginGroup;
  const isLoggedIn = isLoggedInValue ? true : false;

  // get designations
  const { data: designations } = useQueryGetAuthRoleDesignations({
    skip: !isLoggedIn,
  });

  // to logout, we need to logout when logged in with password or when using whodis auth
  const logout = async () => {
    // set BackupLoginGroup to null
    await logOutFromBackupLoginGroup();
    // logout from whodis auth
    await forgetAuthenticationToken({ storage });
  };

  return {
    isLoggedIn,
    /** authedUserUuid may be null if logged in if logged in with backup login group via password */
    authedWhodisUserUuid: whodisUserUuid ?? null,
    authedBackupLoginGroup: authedBackupLoginGroup ?? null,
    role: designations?.[0].role, // todo: deprecate in favor of designations directly
    designations,
    logout,
  };
};
