import React, { CSSProperties, ReactNode } from 'react';

import { colors } from '../../../constants/colors';
import { fonts } from '../../../constants/fonts';

export enum TextType {
  HEADLINE = 'HEADLINE', // main take away - if user is to only read one thing, they should read this
  HEADLINE_SUPPORT = 'HEADLINE_SUPPORT', // i.e., gives more information about what the headline says (smaller and not active)
  TITLE = 'TITLE', // i.e., summary of what a section will talk about
  CONTENT = 'CONTENT', // content for the section
  DETAILS = 'DETAILS', // small details
  EMOJI = 'EMOJI', // emoji's in black
  LABEL = 'LABEL',
}

/**
 * Per best practices rule of thumb in https://instapage.com/blog/mobile-landing-page-design
 */
const textTypeStyles: {
  [index in TextType]: {
    fontFamily: keyof typeof fonts;
    fontSize: { mobile: number; full: number };
    color: keyof typeof colors.text.lightBackground;
  };
} = {
  [TextType.HEADLINE]: {
    fontFamily: 'active',
    fontSize: { mobile: 28, full: 52 },
    color: 'primary',
  },
  [TextType.HEADLINE_SUPPORT]: {
    fontFamily: 'static',
    fontSize: { mobile: 17, full: 33 },
    color: 'secondary',
  },
  [TextType.TITLE]: {
    fontFamily: 'active',
    fontSize: { mobile: 22, full: 25 },
    color: 'primary',
  },
  [TextType.CONTENT]: {
    fontFamily: 'static',
    fontSize: { mobile: 17, full: 17 },
    color: 'primary',
  },
  [TextType.DETAILS]: {
    fontFamily: 'static',
    fontSize: { mobile: 15, full: 15 },
    color: 'secondary',
  },
  [TextType.EMOJI]: {
    fontFamily: 'emoji',
    fontSize: { mobile: 15, full: 15 },
    color: 'primary',
  },
  [TextType.LABEL]: {
    fontFamily: 'static',
    fontSize: { mobile: 13, full: 13 },
    color: 'primary',
  },
};

export const Text = ({
  children,
  type = TextType.CONTENT,
  align, // inherits the alignment by default
  onClick,
  strikeThrough = false,
  inline = false,
}: {
  children: ReactNode;
  type?: TextType;
  align?: CSSProperties['textAlign'];
  onClick?: () => void;
  strikeThrough?: boolean;
  inline?: boolean;
}) => {
  const isWidescreen = false;
  const {
    fontSize: fontSizePerPlatform,
    fontFamily: fontFamilyKey,
    color: colorKey,
  } = textTypeStyles[type];
  const fontSize = fontSizePerPlatform[isWidescreen ? 'full' : 'mobile'];
  const color = colors.text.lightBackground[colorKey];
  const fontFamily = fonts[fontFamilyKey];
  const lineHeight = (() => {
    // smaller font size -> bigger line height -> more readable
    if (fontSize < 25) return 1.4;
    if (fontSize < 35) return 1.2;
    return 1;
  })();
  return (
    <div
      tabIndex={onClick ? 0 : undefined} // text is only tabbable if it has an onclick
      onClick={onClick}
      onKeyUp={
        onClick
          ? (event) => {
              if (event.key === 'Enter') onClick(); // submit onclick when enter is pressed when tabbed over
            }
          : undefined
      }
      style={{
        fontSize,
        fontFamily,
        color,
        lineHeight,
        textAlign: align,
        // overflow: 'hidden', // !: dont add this property, as it breaks the ability to nest text within text (i.e., for onclick inside text)
        ...(onClick
          ? {
              textDecoration: 'underline',
              textDecorationColor: colors.text.lightBackground.placeholder,
              fontWeight: '500' as any,
              cursor: 'pointer',
              display: 'inline-block',
            }
          : { fontWeight: type === TextType.LABEL ? 'bold' : undefined }),
        ...(strikeThrough ? { textDecorationLine: 'line-through' } : {}),

        ...(inline ? { display: 'inline' } : {}),

        // and prevent word breaks from messing up layouts, per https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        hyphens: 'auto',
      }}
    >
      {children}
    </div>
  );
};
