import useSWR from 'swr';
import { HasMetadata } from 'type-fns';

import { AuthRoleDesignation } from '../../../../domain/objects/AuthRoleDesignation';
import { fetchAgainstSvcGateway } from '../util/createSvcGatewayFetcher';

export const useQueryGetAuthRoleDesignations = ({
  skip,
}: {
  skip?: boolean;
}) => {
  const { data, error } = useSWR<{
    designations: HasMetadata<AuthRoleDesignation>[];
  }>(
    skip ? null : { endpoint: '/auth/designations/get', data: {} },
    fetchAgainstSvcGateway,
  );
  return {
    data: data?.designations,
    error: error,
    loading: data === undefined,
  };
};
