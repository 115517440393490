import React, { ReactChild } from 'react';

/**
 * this component just sets a max width on the content and centers it
 *
 * this allows us to focus only on the mobile experience and just expect that it'll look "ok" on desktop
 *
 * that way we dont have to think about the desktop form factor when designing - which is useful since 95% of usage is mobile
 */
export const AppPageMaxWidth = ({
  children,
}: {
  children: (ReactChild | null) | (ReactChild | null)[];
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '700px', width: '100%', position: 'relative' }}>
        {children}
      </div>
    </div>
  );
};
