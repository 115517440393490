import Button from '@mui/material/Button';
import Link from 'next/link';
import React, { ReactNode, useState } from 'react';
import useIsMounted from 'react-is-mounted-hook';

import { colors } from '../../../constants/colors';
import { fonts } from '../../../constants/fonts';

export enum ActionButtonVariant {
  /** primary color AND filled AND elevated */
  CALL_TO_ACTION = 'CALL_TO_ACTION',

  /** primary color AND has an outline AND elevated */
  ACTION_OUTLINE = 'ACTION_OUTLINE',

  /** primary color AND elevated */
  ACTION_ELEVATION = 'ACTION_ELEVATION',

  /** background color gray AND bold outline */
  SELECTED = 'SELECTED',
  /** background color inherited AND light outline */
  NOT_SELECTED = 'NOT_SELECTED',

  /** primary color AND looks like special text until hovered */
  ACTION_TEXT = 'ACTION_TEXT',

  /** regular color, static font, and looks like text until hovered */
  TEXT = 'TEXT',

  /** background color inherited AND black outline */
  AIRY_BUTTON = 'AIRY_BUTTON',
}
interface ActionButtonVariantOptions {
  muiVariant: 'text' | 'outlined' | 'contained';
  muiColor: 'primary' | 'inherit' | 'secondary';
  fontType: keyof typeof fonts;
  elevation: number;
  border?: string;
}
const VARIANT_OPTIONS: {
  [index in ActionButtonVariant]: ActionButtonVariantOptions;
} = {
  [ActionButtonVariant.CALL_TO_ACTION]: {
    muiVariant: 'contained',
    muiColor: 'primary',
    fontType: 'active',
    elevation: 2,
  },
  [ActionButtonVariant.ACTION_OUTLINE]: {
    muiVariant: 'outlined',
    muiColor: 'primary',
    fontType: 'active',
    elevation: 1,
  },
  [ActionButtonVariant.ACTION_ELEVATION]: {
    muiVariant: 'outlined',
    muiColor: 'inherit',
    fontType: 'active',
    elevation: 1,
    border: `1px solid ${colors.border.lightBackground.default}`, // remove the colored border
  },
  [ActionButtonVariant.SELECTED]: {
    muiVariant: 'contained',
    muiColor: 'secondary',
    fontType: 'active',
    elevation: 0,
    border: `2px solid ${colors.text.lightBackground.primary}`, // remove the colored border
  },
  [ActionButtonVariant.NOT_SELECTED]: {
    muiVariant: 'outlined',
    muiColor: 'inherit',
    fontType: 'active',
    elevation: 0,
    border: `1px solid ${colors.accent.grayOutline}`, // remove the colored border
  },
  [ActionButtonVariant.ACTION_TEXT]: {
    muiVariant: 'text',
    muiColor: 'primary',
    fontType: 'active',
    elevation: 0,
  },
  [ActionButtonVariant.TEXT]: {
    muiVariant: 'text',
    muiColor: 'inherit',
    fontType: 'static',
    elevation: 0,
  },
  [ActionButtonVariant.AIRY_BUTTON]: {
    muiVariant: 'outlined',
    muiColor: 'inherit',
    fontType: 'active',
    elevation: 1,
    border: `1px solid ${colors.accent.grayOutline}`, // remove the colored border
  },
};

export enum ActionButtonSize {
  STANDARD = 'STANDARD',
  LARGE = 'LARGE',
}
interface ActionButtonSizeOptions {
  minHeight: number | undefined;
  fontSize: number;
}
const SIZE_OPTIONS: { [index in ActionButtonSize]: ActionButtonSizeOptions } = {
  [ActionButtonSize.LARGE]: {
    minHeight: 45 + 16 + 3,
    fontSize: 21,
  },
  [ActionButtonSize.STANDARD]: {
    minHeight: undefined,
    fontSize: 17,
  },
};

interface ActionOnClickFunction {
  onClick: () => Promise<void> | void;
}
interface ActionHrefLink {
  href: string;
  target?: '_blank'; // blank if new tab
}
interface ActionHashScroll {
  hash: string;
}
type ActionType = ActionOnClickFunction | ActionHrefLink | ActionHashScroll;

const isOnClickAction = (action: ActionType): action is ActionOnClickFunction =>
  !!(action as any).onClick;
const isHrefLinkAction = (action: ActionType): action is ActionHrefLink =>
  !!(action as any).href;
const isHashScrollAction = (action: ActionType): action is ActionHashScroll =>
  !!(action as any).hash;

export const ActionButton = ({
  children,
  variant = ActionButtonVariant.TEXT,
  size = ActionButtonSize.STANDARD,
  action,
  fillContainer,
  disabled,
  buttonRef,
}: {
  children: ReactNode;
  variant?: ActionButtonVariant;
  size?: ActionButtonSize;
  action?: ActionType;
  fillContainer?: boolean;
  disabled?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}) => {
  // define an onClick function w/ loading state handling, if onClick action is defined (since onclick can be async)
  const [isLoading, setIsLoading] = useState(false); // TODO: use isLoading to indicate loading state... make the button "pulse" or "ripple" laterally to indicate movement, without changing size of button
  const isStillMounted = useIsMounted();
  const onClickForOnClickAction =
    action && isOnClickAction(action)
      ? async () => {
          setIsLoading(true);
          await action.onClick();
          if (!isStillMounted()) return; // otherwise we'll try to set state on an unmounted component and react wont like that
          setIsLoading(false);
        }
      : null;

  // define an onClick function to scroll to hash, if hash is defined
  const onClickForHashScrollAction =
    action && isHashScrollAction(action)
      ? () => {
          window.location.hash = `#${action.hash}`;
        }
      : null;

  // define an onClick function for navigating to an href, if href is defined
  const onClickForHrefAction =
    action && isHrefLinkAction(action)
      ? () => {
          // mark it as loading, which will be true until we navigate to the new page
          setIsLoading(true);
        }
      : null;

  // convert our "variant" into variant options
  const variantOptions = VARIANT_OPTIONS[variant];
  const fontFamily = fonts[variantOptions.fontType];
  const elevation = variantOptions.elevation;
  const border = variantOptions.border;

  // convert our "size" into the size options
  const sizeOptions = SIZE_OPTIONS[size];

  const determinedElevation =
    variant === ActionButtonVariant.AIRY_BUTTON ? 0 : elevation;
  const boxShadow = disabled ? 0 : determinedElevation;

  // render the button
  const button = (
    <Button
      ref={buttonRef}
      variant={variantOptions.muiVariant}
      color={variantOptions.muiColor}
      sx={{
        boxShadow,
      }}
      onClick={
        onClickForOnClickAction ??
        onClickForHashScrollAction ??
        onClickForHrefAction ??
        undefined
      }
      href={action && isHrefLinkAction(action) ? action.href : undefined}
      {...(action && isHrefLinkAction(action) && action.target
        ? { target: action.target }
        : undefined)}
      disabled={disabled || isLoading}
      style={{
        textTransform: 'capitalize',
        fontSize: sizeOptions.fontSize,
        fontFamily,
        border,
        margin: variant === ActionButtonVariant.NOT_SELECTED ? 1 : undefined,
        fontWeight: 400,
        minHeight: sizeOptions.minHeight,
        paddingTop: fontFamily === fonts.active ? 10 : 5,
        ...(fillContainer
          ? { width: '100%', height: '100%', flex: 1 }
          : undefined), // if fill
        borderRadius:
          variant === ActionButtonVariant.NOT_SELECTED ||
          variant === ActionButtonVariant.SELECTED
            ? 30
            : 10,
      }}
      css={{
        willChange: 'transform',
        '&:active': {
          transform: 'scale(0.99)',
        },
      }}
    >
      {children}
    </Button>
  );

  // if href action, wrap the button in the next.js link (for proper clientside and serverside routing)
  if (action && isHrefLinkAction(action)) {
    return <Link href={action.href}>{button}</Link>;
  }

  // otherwise, return the button directly
  return button;
};
